<template>
  <div>
    <p>
      <u>Dragonmaw Chasm Racetrack</u>
      <br>-Yep! You will be able to race through the underworld of Forbidden Valley and swoosh across the branches of the Great Tree! Point of entry is a bouncer located in Mantis Rock.
      <br>- Two new Dragonmaw Chasm Race Cars are available at Vendor Yam Waterwolf’s shop.
      <br>- There will of course be new racing Missions and Achievements for you to complete on this exhilarating racetrack!
    </p>
    <p>
      <u>Faction Cars and Rockets</u>
      <br>- Faction Cars! Assembly Blockader, Paradox Blindsider, Sentinel Stalwart and Venture League Hinterlander.
      <br>- Faction Rockets! Assembly Solarblast, Paradox Darkwarp, Sentinel Startalon and Venture League Astroscout.
      <br>- Buy them from your Faction Vendor in Nimbus Station – bring coins and Faction Tokens!
    </p>
    <p>
      <u>Brick Donation for Nexus Tower</u>
      <br>Everyone needs to brick in so Nexus Tower can be built in due time! Find the hungry Nexus Jawbox at the Race Place in Nimbus Station, and donate as many bricks as you possibly can. This is the first community mission in LEGO Universe history, so make sure you are a part of it. Hopefully, Nexus Tower will be the largest build ever seen in LEGO Universe!
    </p>
    <p>
      <u>Nimbus Isle</u>
      <br>This new Nimbus Station property is approximately as big as Avant Grove, so let your Imagination run wild!
    </p>
    <p>
      <u>New Models</u>
      <br>Autumn Helix’s new Safari and Zoo models will allow you to create the perfect setting on your property for…
    </p>
    <p>
      <u>Pets on Property</u>
      <br>That’s right! Place your Pets on one of your Properties in Place Models mode, and add behaviors and movements to them. Find Autumn Helix in Brick Annex in Nimbus Station.
    </p>
    <p>
      <u>New Daily Missions</u>
      <br>- Donate More Bricks! Every day will have a new challenge to donate more bricks in the Nexus Jawbox for the construction of Nexus Tower.
      <br>- Plus many additional very challenging missions, given to you daily by your Faction Representative.
    </p>
    <p>
      <u>Specialty Gear</u>
      <br>- An achievement has been added for equipping all pieces of rank 1 gear. The reward for this achievement is the rank 2 book. You will be unable to purchase the rank 2 gear until you complete the rank 1 achievement. Any players who have currently used the rank 2 book will continue to be able to use and buy new rank 2 gear.
      <br>- An achievement has been added for equipping all pieces of rank 2 gear. The reward for this achievement is the rank 3 book. You will be unable to purchase the rank 3 gear until you complete the rank 2 achievement. Any players who have currently used the rank 3 book will continue to be able to use and buy new rank 3 gear.
    </p>
    <p>
      <u>Known Issues</u>
      <br>- Icons for custom cars, rockets and models may not appear for some players. This issue will be fixed in an upcoming maintenance.
    </p>
  </div>
</template>
